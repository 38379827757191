import React, { useEffect, useState } from 'react'
import { createTemplate, getTemplates } from '../api'
import Loader from '../components/UI/Loader'
import Menu from '../components/Menu'
import Button from '../components/UI/Button'
import { FiPlay, FiPlus, FiX } from 'react-icons/fi'
import Auth from '../components/Auth'
import Error from '../components/UI/Error'
import Label from '../components/UI/Label'
import { GoSearch } from 'react-icons/go'
import '../i18n'
import { useTranslation } from 'react-i18next'

function Templates() {
  const { t } = useTranslation()
  const [templates, setTemplates] = useState()
  const [catalog, setCatalog] = useState()
  const [format, setFormat] = useState()
  const [search, setSearch] = useState('')
  useEffect(() => {
    getTemplates().then(setTemplates)
  }, [])
  if (templates?.error) return <Error>{t('global.resourceNotFound')}</Error>
  if (templates === undefined) return <Loader />
  const formats = [...new Set(templates.map(t => t.config?.id))].map(f => templates.find(t => t.config?.id === f).config).filter(f => f)
  const catalogs = [...new Set(templates.map(t => t.catalog?.id))].map(c => templates.find(t => t.catalog?.id === c).catalog).filter(c => c)
  const filteredTemplates = templates
  .filter(t => !format || t.config?.id === format)
  .filter(t => !catalog || t.catalog?.id === catalog)
  .filter(t => !search || t.name.match(new RegExp(search, 'i')))

  const handleSearch = e => setSearch(e.target.value)

  return (
    <div className="w-screen h-screen p-4 overflow-y-auto">
      <Menu title={t('global.models')}>
        <div className="w-full flex justify-between items-center">
          <div className="w-full flex justify-center">
            <Label title="Catalogue" className="mr-2 -mt-1">
              <select value={catalog} onChange={e => setCatalog(parseInt(e.target.value)) }>
                <option value="">-</option>
                {catalogs && !catalogs.error && catalogs.map(catalog =>
                  <option key={catalog.id} value={catalog.id}>{catalog.name}</option>
                )}
              </select>
            </Label>
            <Label title="Format" className="mr-2 -mt-1">
              <select value={format} onChange={e => setFormat(parseInt(e.target.value)) }>
                <option value="">-</option>
                {formats && !formats.error && formats.map(format => 
                  <option key={format.id} value={format.id}>{format.name}</option>
                )}
              </select>
            </Label>
          </div>
          <Label title="Template" className="mr-2 -mt-1 relative">
            <GoSearch className="absolute top-1/2 mx-3"/>
            <input type="text" placeholder={t('templates.findTemplate')} className="px-3 pl-8 mr-8 w-64" onChange={handleSearch} value={search}/>
          </Label>
          
          <Button
            Icon={FiPlus}
            onClick={async () => {
              const created = await createTemplate({ name: t('templates.newModel'), config: format?.id || formats[0].id })
              window.location = `/template/${created.id}`
            }}
            className="mx-1 mt-1">
            {t('global.new')}
          </Button>
        </div>
      </Menu>
      <div className="pt-8 lg:pt-16">
        {catalogs.map(catalog =>
          <div key={catalog.id} className="m-14">
            { filteredTemplates.filter(t => t.catalog?.id === catalog.id).length !== 0 && <div className="text-xl text-center font-medium mb-6">{catalog.name}</div> }
            <div className="flex justify-center items-center flex-wrap">
              { filteredTemplates.filter(t => t.catalog?.id === catalog.id).map((template, i) =>
                  <a
                    key={catalog.id}
                    href={`/template/${template.id}`}
                    className="relative flex flex-col justify-center items-center m-2 transform transition-transform duration-300 ease-in-out cursor-pointer hover:scale-105">
                    <img src={template.render?.url} className="h-80 bg-gray-100 bg-opacity-50" style={{ filter: 'drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1))' }} />
                    <div className="text-sm font-medium text-gray-600 mt-2">{template.name}</div>
                  </a>
              ) }
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default function AuthTemplates() {
  return (
    <Auth>
      <Templates />
    </Auth>
  )
}
